import { ErrorMessage, Field } from 'formik';
import Select, { ActionMeta, components } from 'react-select';
import { ChangeEvent, FormEvent, forwardRef, useState, WheelEvent } from 'react';
import { MultipleDatePickerField } from './MultipleDataPickerField';
import { TextEditorField } from './TextEditorField';
import { TimePickerField } from './TimePickerField';
import { IFieldWrapperProps } from '../../types/Interfaces';
import { useTranslation } from 'react-i18next';
import { customStyles } from '../../utils/SelectStyles';

const FieldWrapper = (
  {
    children,
    title,
    desc,
    inputError,
    inputTouched,
    inputName,
    inputPlaceholder,
    defaultValue,
    options,
    input,
    onChange,
    onFocus,
    onBlur,
    onChangeForcontrolled,
    select,
    customClass,
    selectStyle,
    type,
    border,
    customPadding,
    tooltip,
    tooltipTitle,
    tooltipHeader,
    tooltipText,
    radio,
    noPadding,
    tick,
    search,
    disabled,
    textArea,
    maxLength = 50,
    multi,
    date,
    textEditor,
    selectRef,
    multipleDates,
    minDate,
    maxDate,
    timePicker,
    value,
    controlledInput,
    noMinDate,
    formik,
    showMaxLengthHint,
    showUnit,
    unit,
    dateFormat,
    hideErrorIcon,
    clear,
    filterKeys,
    dir,
    showPassword,
    FWcustomStyles
  }: IFieldWrapperProps,
  ref: React.Ref<HTMLDivElement>
) => {
  const length = value?.length || 0;
  const { t } = useTranslation();

  const [remainingCharachters, setRemainingCharachters] = useState(maxLength - length || maxLength);
  const [showPasswordState, setShowPasswordState] = useState(false);

  const handleKeyUp = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRemainingCharachters(maxLength - e.target.value?.length || 0);
  };

  return (
    <div
      className={`field_wrapper_container ${customPadding && 'custom-padding'} ${noPadding && 'no-padding'} ${border && 'add-border'} ${customClass}`}
      ref={ref}
      style={FWcustomStyles}
    >
      {title && (
        <div className={`header ${radio && 'radio-header'}`}>
          <h5 className="title">
            {title}
            {tooltipTitle && (
              <span className="desc">
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    opacity="0.6"
                    d="M8 0.5C12.4062 0.5 16 4.09375 16 8.5C16 12.9375 12.4062 16.5 8 16.5C3.5625 16.5 0 12.9375 0 8.5C0 4.09375 3.5625 0.5 8 0.5ZM8 4.5C7.4375 4.5 7 4.96875 7 5.5C7 6.0625 7.4375 6.5 8 6.5C8.53125 6.5 9 6.0625 9 5.5C9 4.96875 8.53125 4.5 8 4.5ZM9.25 12.5C9.65625 12.5 10 12.1875 10 11.75C10 11.3438 9.65625 11 9.25 11H8.75V8.25C8.75 7.84375 8.40625 7.5 8 7.5H7C6.5625 7.5 6.25 7.84375 6.25 8.25C6.25 8.6875 6.5625 9 7 9H7.25V11H6.75C6.3125 11 6 11.3438 6 11.75C6 12.1875 6.3125 12.5 6.75 12.5H9.25Z"
                    fill="#000"
                  />
                </svg>
                <div className="tooltip-box">
                  {tooltipHeader && <h6>{tooltipHeader}</h6>}
                  {tooltipText && <p className="desc">{tooltipText}</p>}
                </div>
              </span>
            )}
          </h5>
          {desc && (
            <div className="desc">
              {desc}
              {tooltip && (
                <>
                  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      opacity="0.6"
                      d="M8 0.5C12.4062 0.5 16 4.09375 16 8.5C16 12.9375 12.4062 16.5 8 16.5C3.5625 16.5 0 12.9375 0 8.5C0 4.09375 3.5625 0.5 8 0.5ZM8 4.5C7.4375 4.5 7 4.96875 7 5.5C7 6.0625 7.4375 6.5 8 6.5C8.53125 6.5 9 6.0625 9 5.5C9 4.96875 8.53125 4.5 8 4.5ZM9.25 12.5C9.65625 12.5 10 12.1875 10 11.75C10 11.3438 9.65625 11 9.25 11H8.75V8.25C8.75 7.84375 8.40625 7.5 8 7.5H7C6.5625 7.5 6.25 7.84375 6.25 8.25C6.25 8.6875 6.5625 9 7 9H7.25V11H6.75C6.3125 11 6 11.3438 6 11.75C6 12.1875 6.3125 12.5 6.75 12.5H9.25Z"
                      fill="#000"
                    />
                  </svg>
                  <div className="tooltip-box">
                    {tooltipHeader && <h6>{tooltipHeader}</h6>}
                    {tooltipText && <p className="desc">{tooltipText}</p>}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      )}
      {input && (
        <div className="input_wrapper">
          <div className="field" dir={dir}>
            <Field
              name={inputName}
              render={({ field, form }: { field: any; form: any }) => (
                <input
                  type={type && 'password' && showPasswordState ? 'text' : type}
                  placeholder={inputPlaceholder}
                  name={inputName}
                  className={`input-field ${inputError && inputTouched && 'input-error'}`}
                  maxLength={maxLength || 50}
                  disabled={disabled}
                  {...field}
                  onKeyUp={handleKeyUp}
                  onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                    field.onBlur(e);
                    if (onBlur) {
                      onBlur(e);
                    }
                  }}
                  onWheel={(e: React.WheelEvent<HTMLInputElement>) => (e.target as HTMLInputElement).blur()}
                />
              )}
            />

            {showUnit && <span className="fixed-place-holder">{unit}</span>}
            {showPassword && (
              <div className="tick showPassword pointer" onClick={() => setShowPasswordState(!showPasswordState)}>
                {!showPasswordState && (
                  <svg stroke="black" fill="black" strokeWidth="0" viewBox="0 0 576 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"></path>
                  </svg>
                )}
                {showPasswordState && (
                  <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 640 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"></path>
                  </svg>
                )}
              </div>
            )}
            {!inputError && tick && (
              <div className="tick">
                <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M16.6304 0.395476C17.1232 0.882215 17.1232 1.73401 16.6304 2.22075L6.92698 12.6045C6.47213 13.1318 5.67614 13.1318 5.22129 12.6045L0.369565 7.41264C-0.123188 6.9259 -0.123188 6.0741 0.369565 5.58736C0.824415 5.06006 1.6204 5.06006 2.07525 5.58736L6.05518 9.84633L14.9247 0.395476C15.3796 -0.131825 16.1756 -0.131825 16.6304 0.395476Z"
                    fill={'#000'}
                  />
                </svg>
              </div>
            )}

            {showMaxLengthHint && (
              <p className="max_length_hint">
                {remainingCharachters} / {maxLength} {t('charactersLeft')}
              </p>
            )}
          </div>
          <p className="error">
            <ErrorMessage name={inputName || 'defaultName'} />
          </p>
          {!showUnit && inputError && inputTouched && !hideErrorIcon && dir != 'rtl' && <div className="error-icon">!</div>}
        </div>
      )}
      {controlledInput && (
        <div className="input_wrapper">
          <div className="field">
            <Field
              type={type && type}
              placeholder={inputPlaceholder}
              name={inputName}
              className={`input-field ${inputError && inputTouched && 'input-error'}`}
              maxLength="255"
              onChange={(e: FormEvent<HTMLInputElement>) => {
                onChangeForcontrolled && onChangeForcontrolled(e);
                formik && formik.setFieldValue(inputName || 'defaultName', e?.currentTarget?.value);
              }}
              disabled={disabled}
            />
            {!inputError && tick && (
              <div className="tick">
                <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M16.6304 0.395476C17.1232 0.882215 17.1232 1.73401 16.6304 2.22075L6.92698 12.6045C6.47213 13.1318 5.67614 13.1318 5.22129 12.6045L0.369565 7.41264C-0.123188 6.9259 -0.123188 6.0741 0.369565 5.58736C0.824415 5.06006 1.6204 5.06006 2.07525 5.58736L6.05518 9.84633L14.9247 0.395476C15.3796 -0.131825 16.1756 -0.131825 16.6304 0.395476Z"
                    fill={'#000'}
                  />
                </svg>
              </div>
            )}

            {/* <p className="error">
              <ErrorMessage name={inputName} />
            </p> */}
          </div>
        </div>
      )}
      {textArea && (
        <div className="input_wrapper">
          <div className="field">
            <Field
              as={'textarea'}
              rows={7}
              name={inputName}
              placeholder={inputPlaceholder}
              className={`input-field ${inputError && inputTouched && 'input-error'}`}
              maxLength={maxLength}
              disabled={disabled}
              onKeyUp={handleKeyUp}
            />
            {showMaxLengthHint && (
              <p className="max_length_hint">
                {remainingCharachters} / {maxLength} {t('charactersLeft')}
              </p>
            )}{' '}
            <p className="error">
              <ErrorMessage name={inputName || 'defaultName'} />
            </p>
          </div>
        </div>
      )}
      {textEditor && (
        <div className="input_wrapper">
          <div className="field">
            <TextEditorField name={inputName || 'defaultName'} className={`${inputError && inputTouched && 'ql-error'}`} />
            <p className="error">
              <ErrorMessage name={inputName || 'defaultName'} />
            </p>
          </div>
        </div>
      )}
      {select && (
        <div className="input_wrapper">
          <div className="field">
            <Select
              isSearchable={search || false}
              isClearable={clear || false}
              isMulti={multi || false}
              options={options}
              name={inputName}
              placeholder={inputPlaceholder}
              className={`select-drop-down ${inputError && inputTouched && 'input-error'} ${customClass || ''} ${search && 'searchable_select'} ${disabled && 'disabled'}`}
              noOptionsMessage={() => t('no_options')}
              classNamePrefix="react-select"
              styles={selectStyle}
              defaultValue={defaultValue}
              onChange={onChange as (newValue: unknown, actionMeta: ActionMeta<unknown>) => void}
              onFocus={onFocus}
              onBlur={onBlur}
              isDisabled={disabled}
              value={value}
              ref={selectRef}
              filterOption={(option, inputValue) => {
                if (inputValue.trim().length == 0) return true;

                const dataToFilter = [option.label, option.value, ...(filterKeys ? filterKeys?.map((key) => (option?.data as { [key: string]: string })?.[key]) : [])];
                return dataToFilter?.some((data) => data?.toString()?.toLowerCase()?.includes(inputValue.toLowerCase().trim()));
              }}
            />
            <p className="error">
              <ErrorMessage name={inputName || 'defaultName'} />
            </p>
          </div>
        </div>
      )}

      {timePicker && (
        <div className="input_wrapper">
          {/* <div className="field"> */}
          <TimePickerField name={inputName || 'defaultName'} disabled={disabled} />
          <p className="error">
            <ErrorMessage name={inputName || 'defaultName'} />
          </p>
          {/* </div> */}
        </div>
      )}

      {multipleDates && (
        <div className="input_wrapper">
          {/* <div className="field"> */}
          <MultipleDatePickerField name={inputName || 'defaultName'} minDate={minDate} maxDate={maxDate} dateFormat={dateFormat} disabled={disabled} />
          <p className="error">
            <ErrorMessage name={inputName || 'defaultName'} />
          </p>
          {/* </div> */}
        </div>
      )}
      {children}
    </div>
  );
};

export default forwardRef(FieldWrapper);
