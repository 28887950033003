import { useEffect, useState } from 'react';
import SectionHeader from '../../../../components/SectionHeader';
import FieldWrapper from '../../../../components/formInputs/FieldWrapper';
import { FieldArray, FormikValues } from 'formik';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandAccordion from './ExpandAccordion';
import BalloonsInputs from './BalloonsInputs';
import { IProductData } from '../../types/interfaces';
import { useTranslation } from 'react-i18next';

const BalloonsForm = ({ formik, productData }: { formik: FormikValues; productData: IProductData }) => {
  const [expanded, setExpanded] = useState(0);
  const [count, setCount] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    setCount(productData?.conditions?.length || 0);
  }, [productData]);

  return (
    <SectionHeader title={t('productBalloonConditions')} customStyle="last">
      <FieldWrapper noPadding>
        <div className={`input-wrapper start-times`}>
          <FieldArray
            name="balloon_conditions"
            render={(arrayHelpers) => (
              <div>
                {formik?.values?.balloon_conditions?.map((res: any, index: number) => (
                  <div className={`field-array-container`} key={index}>
                    <Accordion
                      expanded={expanded == index}
                      onChange={(e) => {
                        e.stopPropagation();
                        setExpanded(expanded == index ? -1 : index);
                      }}
                    >
                      <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className={`header-container ${expanded != index && 'expand'}`}
                        sx={{
                          '&.Mui-focusVisible': {
                            backgroundColor: '#000'
                          }
                        }}
                      >
                        <p>
                          <ExpandAccordion expand={expanded == index} /> {`Condition Details`}
                        </p>
                        {
                          <p
                            onClick={(e) => {
                              e.stopPropagation();
                              setCount((count) => count - 1);
                              arrayHelpers.remove(index);
                            }}
                          >
                            Remove
                            <svg width="11" height="13" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M11.8125 2.5C12.0312 2.5 12.25 2.71875 12.25 2.9375C12.25 3.18359 12.0312 3.375 11.8125 3.375H11.293L10.6094 13.1367C10.5273 14.0664 9.76172 14.75 8.85938 14.75H3.36328C2.46094 14.75 1.69531 14.0664 1.61328 13.1367L0.929688 3.375H0.4375C0.191406 3.375 0 3.18359 0 2.9375C0 2.71875 0.191406 2.5 0.4375 2.5H3.03516L3.74609 1.37891C3.96484 0.996094 4.40234 0.75 4.83984 0.75H7.38281C7.82031 0.75 8.25781 0.996094 8.47656 1.37891L9.1875 2.5H11.8125ZM4.83984 1.625C4.70312 1.625 4.56641 1.70703 4.48438 1.84375L4.04688 2.5H8.17578L7.73828 1.84375C7.65625 1.70703 7.51953 1.625 7.38281 1.625H4.83984ZM10.418 3.375H1.80469L2.48828 13.082C2.51562 13.5195 2.89844 13.875 3.36328 13.875H8.85938C9.32422 13.875 9.70703 13.5195 9.73438 13.082L10.418 3.375Z"
                                fill="white"
                              />
                            </svg>
                          </p>
                        }
                      </AccordionSummary>
                      <AccordionDetails>
                        <BalloonsInputs index={index} formik={formik} productData={productData} />
                      </AccordionDetails>
                    </Accordion>
                  </div>
                ))}

                <div
                  className="add-time-button"
                  onClick={() => {
                    arrayHelpers.push({
                      vehicle_model_id: '',
                      salary_transferred_to: '',
                      balloon_percentage: ''
                    });
                    setCount((count) => count + 1);
                    setExpanded(count);
                  }}
                >
                  <svg width="16" height="16" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M8.9375 12.625V9.8125H6.125C5.80859 9.8125 5.5625 9.56641 5.5625 9.25C5.5625 8.96875 5.80859 8.6875 6.125 8.6875H8.9375V5.875C8.9375 5.59375 9.18359 5.3125 9.5 5.3125C9.78125 5.3125 10.0625 5.59375 10.0625 5.875V8.6875H12.875C13.1562 8.6875 13.4375 8.96875 13.4375 9.25C13.4375 9.56641 13.1562 9.8125 12.875 9.8125H10.0625V12.625C10.0625 12.9414 9.78125 13.1875 9.5 13.1875C9.18359 13.1875 8.9375 12.9414 8.9375 12.625ZM18.5 9.25C18.5 14.2422 14.457 18.25 9.5 18.25C4.50781 18.25 0.5 14.2422 0.5 9.25C0.5 4.29297 4.50781 0.25 9.5 0.25C14.457 0.25 18.5 4.29297 18.5 9.25ZM9.5 1.375C5.14062 1.375 1.625 4.92578 1.625 9.25C1.625 13.6094 5.14062 17.125 9.5 17.125C13.8242 17.125 17.375 13.6094 17.375 9.25C17.375 4.92578 13.8242 1.375 9.5 1.375Z"
                      fill="#211D33"
                    />
                  </svg>
                  {t('addCondition')}
                </div>
              </div>
            )}
          />
        </div>
      </FieldWrapper>
    </SectionHeader>
  );
};

export default BalloonsForm;
