import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../components/buttons/Button';
import ListHeader from '../../../components/ListHeader';
import { useTranslation } from 'react-i18next';
import CommonHighlights from '../../../modules/serviceProvider/components/CommonHighlights';
import DistributorInfo from '../../../modules/distributors/components/DistributorInfo';
import { generalGet } from '../../../API/api';
import { useDispatch } from 'react-redux';
import { useContext, useEffect, useMemo, useState } from 'react';
import { setBreadCrumbsData } from '../../../store/redux/breadCrumbsData';
import { useQuery } from '@tanstack/react-query';
import { authContext } from '../../../store/context/authContext';
import BookingsTable from '../../../modules/sales/components/BookingsTable';
import moment from 'moment';
import BasicInfoSkeleton from '../../../components/loaders/BasicInfoSkeleton';
import TableSkeleton from '../../../components/loaders/TableSkeleton';
import { IServiceInfo } from '../../../modules/serviceProvider/types/interfaces';
import Cookies from 'js-cookie';
import { hasPermission } from '../../../utils/HelperFunctions';

interface salesData {
  first_name: string;
  last_name: string;
  email: string;
  mobile: string;
  address_line: string;
  created_at: string;
  bookings: any;
  last_booking?: string;
  total_sales?: string;
  total_sales_value?: string;
  active_applications?: string;
  address?: any;
}

const SalesDetails = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [refetch, setRefetch] = useState(false);
  const { catchError } = useContext(authContext);
  const [salesData, setSalesData] = useState<salesData>();
  const { id } = useParams();

  const { data, isSuccess, isLoading, error } = useQuery({
    queryKey: ['sellerDetails', refetch, i18n],
    queryFn: () => generalGet(`/admin/users/${id}`),
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    error && catchError(error);
  }, [error]);

  useEffect(() => {
    const reqData = data?.data?.data;
    if (isSuccess) {      
      setSalesData(reqData);
      dispatch(
        setBreadCrumbsData({
          links: [{ label: t('sales'), path: '/sales' }],
          page_title: reqData.first_name,
          salesId: id,
          editBtn: {
            name: t('editSeller'),
            path: `/sales/create-sales-executive/${id}`,
            permission: 'users.show'
          }
        })
      );
    }
  }, [isSuccess, data, id]);

  useEffect(() => {
    id && Cookies.set('seller_ID', id);
    return () => Cookies.remove('seller_ID');
  }, [id]);

  const tableHeaders = [
    { label: t('appNo'), customClass: 'pro_id_col' },
    { label: t('make&model'), customClass: 'text-center' },
    { label: t('Distributor'), customClass: 'text-center' },
    { label: t('serviceProvider'), customClass: 'registration_date_col text-center' },
    { label: t('step'), customClass: 'text-center' },
    { label: t('status'), customClass: 'text-center' },
    { label: t('owner'), customClass: 'text-center' },
    { label: t('actions'), customClass: 'actions_col' }
  ];

  const highlights = useMemo(() => {
    return [
      {
        title: t('totalSales'),
        number: salesData?.total_sales
      },
      {
        title: `${t('totalSalesValue')} (${t('sar')})`,
        number: salesData?.total_sales_value
      },
      {
        title: t('activeApplications'),
        number: salesData?.active_applications
      },
      {
        title: t('lastBooking'),
        number: salesData?.last_booking
      }
    ];
  }, [salesData, t]);

  if (isLoading)
    return (
      <div>
        <BasicInfoSkeleton />
        <TableSkeleton columns={5} />
      </div>
    );

  return (
    <div className="page_content service_details_page">
      <DistributorInfo
        id={
          hasPermission(['users.show']) && {
            route: `/sales/create-sales-executive/${id}`,
            text: t('editSeller')
          }
        }
        title={t('sellerInfo')}
        data={[
          { key: t('name'), value: salesData?.first_name + ' ' + salesData?.last_name || '-' },
          { key: t('email'), value: salesData?.email || '-' },
          { key: t('address'), value: (salesData?.address && salesData.address.city + ', ' + salesData.address.region + ', ' + salesData.address.street) || '-' },
          { key: t('phone'), value: salesData?.mobile || '-' }
        ]}
        btnCustomClass="generalFixedButton"
      />
      <ListHeader customClass="row noMargin">
        <h2>{t('sellerStats')}</h2>
      </ListHeader>
      <CommonHighlights data={highlights as IServiceInfo[]} />
      <div className="table-wrapper">
        <ListHeader customClass="row">
          <h2>{t('listOfBookings')}</h2>
        </ListHeader>
        <BookingsTable tableHeaders={tableHeaders} data={salesData?.bookings} noDataMessage={t('No_bookings_found')} setRefetchData={setRefetch} />
      </div>
    </div>
  );
};

export default SalesDetails;
