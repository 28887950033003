import { useState } from 'react';
import ModalContainer from '../../../components/ModalContainer';
import DeleteModal from '../../../components/DeleteModal';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { editIcon } from '../../../config/variables';
import { hasPermission } from '../../../utils/HelperFunctions';

const UsersTableContainer = ({ tableHeaders, data, noDataMessage, setRefetchData, border, setOpenModel,
    setUserId }: any) => {

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const { t } = useTranslation()
    const { roles } = useSelector((store: any) => store.rolesData)
    const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' });


    return (
        <div className={`table_container ${border && "border"} ${isDesktop && "user_table"}`}>
            <div className="table_header">
                {tableHeaders?.map((header: any, index: number) => (
                    <span className={`head ${header.customClass}`} key={index}>{header.label}</span>
                ))}
            </div>
            <div className={`table_data ${(!data || data?.length == 0 || data?.length == undefined ) && "no_data"}`}>
                {data?.length > 0 ?
                    <>
                        {data?.map((item: any, index: number) => (
                            <div className="item  has_logo" key={index}>
                                <div className="column  " >{item?.first_name || "-"}</div>
                                <div className="column  " >{item?.last_name || "-"}</div>
                                <div className="column  " >{item?.email || "-"}</div>
                                <div className="column  " >{item?.mobile || "-"}</div>
                                {/* <div className="column " >{item?.city || "-"}</div> */}
                                <div className="column  " >{item?.profession || "-"}</div>
                                <div className="column  " >{roles?.find((role: any) => role.value == item?.role_id)?.label || "-"}</div>
                                <div className={`column  status_col clickable capitalize`} onClick={(e) => { e.stopPropagation(); setDeleteModalOpen(item.id) }
                                }
                                ><div className={`${item?.status == 2 ? "active" : ""} `}>{`${item?.status == 2 ? t("active") : t("disabled")}`}</div></div>
                                {hasPermission(['service_providers.crud']) &&
                                    <div className="column actions actions_col ">
                                        <div className="action_btn edit" onClick={(e) => { e.stopPropagation(); setUserId(item.id); setOpenModel(2); }}>
                                            {editIcon}
                                        </div>
                                    </div>
                                }
                            </div>
                        ))}
                    </>
                    :
                    <h6>{noDataMessage}</h6>
                }
            </div>
            {deleteModalOpen && (
                <ModalContainer small>
                    <DeleteModal
                        id={deleteModalOpen}
                        setModal={setDeleteModalOpen}
                        setRefetchData={setRefetchData}
                        route={`/users/${deleteModalOpen}/toggle-status`}
                        successMsg={t("user_deleted")}
                        warningMsg={t("user_status_update")}
                        post />
                </ModalContainer>
            )}
        </div>
    );
}

export default UsersTableContainer;