import { useDispatch, useSelector } from 'react-redux';
import { setBreadCrumbsData } from '../../../store/redux/breadCrumbsData';
import { Fragment, useContext, useEffect, useState, useTransition } from 'react';
import { useQuery } from '@tanstack/react-query';
import { generalGet } from '../../../API/api';
import TableSkeleton from '../../../components/loaders/TableSkeleton';
import ListHeader from '../../../components/ListHeader';
import SearchInputField from '../../../components/SearchInputField';
import { getFilterQuery, hasPermission, searchFilterLogic } from '../../../utils/HelperFunctions';
import { useTranslation } from 'react-i18next';
import { authContext } from '../../../store/context/authContext';
import { IUser } from '../../../modules/users/types/interfaces';
import { paginationControlStyles, sortStyles } from '../../../utils/SelectStyles';
import Select, { GroupBase, OptionsOrGroups } from 'react-select';
import { TOptions } from 'i18next';
import UsersTableContainer from '../../../modules/users/components/UsersTableContainer';
import { IMeta } from '../../../modules/applications/types/interfaces';
import { Pagination, Stack } from '@mui/material';

const Users = () => {
  const { catchError } = useContext(authContext);
  const { t, i18n } = useTranslation();
  const [selectedRoleOption, setSelectedRoleOption] = useState();
  const [selectedSortingOption, setSelectedSortingOption] = useState();
  const [searchInput, setSearchInput] = useState('');
  const [, startTransition] = useTransition();
  const [refetch, setRefetch] = useState(false);
  const [users, setUsers] = useState<IUser[]>();
  const [shownList, setShownList] = useState<IUser[]>();
  const [sortionsOptions, setSortionsOptions] = useState<OptionsOrGroups<TOptions, GroupBase<TOptions>>>([]);

  const { roles: rolesOptions } = useSelector((store: any) => store.rolesData);

  const dispatch = useDispatch();

  dispatch(
    setBreadCrumbsData({
      links: [{ label: t('users'), path: '/users' }],
      page_title: t('users')
    })
  );

  const [filters, setFilters] = useState<{ role_name: string | null; sort_by: string | null }>({
    role_name: null,
    sort_by: null
  });

  const [metaData, setMetaData] = useState<IMeta>();
  const [currentRoute, setCurrentRoute] = useState('/admin/users?page=1&limit=25');
  const [perPageOptions] = useState([
    { value: '10', label: '10' },
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '100', label: '100' }
  ]);
  const [selectedPerPage, setSelectedPerPage] = useState({ value: '25', label: '25' });

  const { data, isSuccess, isLoading, error } = useQuery({
    queryKey: ['users', refetch, i18n, filters, currentRoute],
    // queryFn: () => generalGet(`/admin/users?limit=100&${getFilterQuery(filters)}`),
    queryFn: () => generalGet(`${currentRoute}&${getFilterQuery(filters)}`),
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    error && catchError(error);
  }, [error]);

  useEffect(() => {
    const reqData = data?.data?.data;
    isSuccess && setUsers(reqData);
    isSuccess && data?.data?.meta && !Array.isArray(data?.data?.meta) && setMetaData(data?.data?.meta);
  }, [isSuccess, data]);

  const tableHeaders = [
    { label: t('first_name') },
    { label: t('last_name') },
    { label: t('email') },
    { label: t('phone') },
    { label: t('profession') },
    { label: t('role') },
    { label: t('status'), customClass: 'status_col' },
    hasPermission(['users.edit', 'owned-users.edit']) && { label: t('actions'), customClass: 'actions_col' }
  ];

  useEffect(() => {
    // if (searchInput) {
    //   const results = searchFilterLogic({
    //     searchInput: searchInput,
    //     listOfData: users?.map((ele) => ({ ...ele, name: ele.first_name + ' ' + ele.last_name })) || [],
    //     keys: ['name', 'email']
    //   });
    //   startTransition(() => {
    //     setShownList(results);
    //   });
    // } else {
    //   setShownList(users);
    // }
    setShownList(users);
  }, [users, searchInput]);

  useEffect(() => {
    const sorting = [
      { label: 'Sort by recent', value: 'desc' },
      { label: 'Sort by oldest', value: 'asc' }
    ];
    setSortionsOptions(sorting);
  }, [users]);

  const filterRole = (e: any) => {
    setSelectedRoleOption(e);
    setFilters({
      ...filters,
      role_name: e.value ? e.label : null
    });
    setSearchInput('');
  };

  const filterSorting = (e: any) => {
    setSelectedSortingOption(e);
    setFilters({
      ...filters,
      sort_by: e.value
    });
  };

  useEffect(() => {
    setShownList(users);
  }, [users]);

  const [page, setPage] = useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    setCurrentRoute(`/admin/users?page=${value}&limit=${selectedPerPage.value}&q=${searchInput}`);
  };

  useEffect(() => {
    setCurrentRoute(`/admin/users?page=1&limit=${selectedPerPage.value}&q=${searchInput}`);
  }, [selectedPerPage, searchInput]);

  if (isLoading && !searchInput) return <TableSkeleton columns={10} withoutButton />;

  return (
    <div className="services-page-container">
      <ListHeader>
        <div className="filter_container">
          <SearchInputField placeholder={t('search_by_name_or_email')} setSearchInput={setSearchInput} searchInput={searchInput} />
          {metaData && (
            <Fragment>
              <Select
                value={selectedRoleOption || null}
                placeholder={t('role')}
                isSearchable={true}
                isClearable={false}
                isMulti={false}
                options={[{ label: 'All Roles', value: '' }, ...rolesOptions]}
                className={`select-drop-down`}
                noOptionsMessage={() => t('no_options')}
                classNamePrefix="react-select"
                styles={sortStyles as any}
                onChange={filterRole}
              />
              <Select
                value={selectedSortingOption}
                placeholder={t('sort_by')}
                isSearchable={false}
                isClearable={false}
                isMulti={false}
                options={sortionsOptions}
                className={`select-drop-down`}
                noOptionsMessage={() => t('no_options')}
                classNamePrefix="react-select"
                styles={sortStyles as any}
                onChange={filterSorting}
              />
            </Fragment>
          )}
        </div>
      </ListHeader>
      <UsersTableContainer tableHeaders={tableHeaders} data={shownList} noDataMessage={t('No_users_found')} setRefetchData={setRefetch} />
      {metaData && (
        <Stack spacing={2} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingTop: '1rem' }}>
          <div className="" style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            {t('rowsPerPage')}{' '}
            <Select
              value={selectedPerPage}
              placeholder={t('perPage')}
              isSearchable={false}
              isClearable={false}
              isMulti={false}
              options={perPageOptions}
              className={`select-drop-down `}
              noOptionsMessage={() => t('no_options')}
              classNamePrefix="react-select"
              styles={{ ...sortStyles, ...paginationControlStyles }}
              onChange={(e) => {
                e && setSelectedPerPage(e);
                setCurrentRoute(`/admin/users?page=${metaData?.current_page}&limit=${e?.value}`);
              }}
            />
          </div>
          {Number(metaData?.last_page) > 1 && <Pagination count={Number(metaData?.last_page)} page={page} onChange={handleChange} />}{' '}
        </Stack>
      )}
    </div>
  );
};

export default Users;
