import { useTranslation } from 'react-i18next';
import FieldWrapper from '../../../../components/formInputs/FieldWrapper';
import { ErrorMessage } from 'formik';
import Checkbox from '../../../../components/formInputs/Checkbox';
import { useState } from 'react';
import { SalaryToCheck, NationalitiesCheck } from '../../../../config/FixedData';

const ConditionsInputs = ({ formik, index, workSectors }: any) => {
  const { t, i18n } = useTranslation();
  const [all, setAll] = useState(false);
  const [allNationalities, setAllNationalities] = useState(false);
  const [allSalaryTransfer, setAllSalaryTransfer] = useState(false);
  return (
    <>
      <div className="inputs_group">
        <FieldWrapper
          title={t('servicePeriod')}
          inputName={`conditions.${index}.service_period`}
          // inputPlaceholder={`${t("ex")} 21`}
          inputError={formik?.errors?.conditions && formik?.errors?.conditions[index]?.service_period}
          inputTouched={formik?.touched?.conditions && formik?.touched?.conditions[index]?.service_period}
          type="number"
          input
          showUnit
          unit={`${'\u2265'} ${t('month')}`}
        />
        <FieldWrapper
          title={t('minimumSalary')}
          inputName={`conditions.${index}.minimum_salary`}
          // inputPlaceholder={`${t("ex")} 1000`}
          inputError={formik?.errors?.conditions && formik?.errors?.conditions[index]?.minimum_salary}
          inputTouched={formik?.touched?.conditions && formik?.touched?.conditions[index]?.minimum_salary}
          type="number"
          input
          showUnit
          unit={t('sar')}
        />
      </div>
      <div className="inputs_group">
        <FieldWrapper title={t('nationality')}>
          <div className="checkboxes_wrapper row">
            <label>
              <input
                checked={allNationalities}
                id={`all`}
                type="checkbox"
                value={'all'}
                onChange={(e) => {
                  setAllNationalities(!allNationalities);
                  formik?.setFieldValue(`conditions.${index}.nationality`, !allNationalities ? NationalitiesCheck.map((item: any) => item.value) : []);
                }}
              />
              {t('all')}
            </label>
            {NationalitiesCheck?.map((item: any, i: number) => (
              <Checkbox
                workSectors={NationalitiesCheck as any}
                name={`conditions.${index}.nationality`}
                key={i}
                item={item}
                value={
                  formik?.values?.conditions[index]?.nationality && Array.isArray(formik?.values?.conditions[index]?.nationality)
                    ? formik?.values?.conditions[index]?.nationality?.some((checkedItem: string) => checkedItem == item?.value)
                    : false
                }
                checked={formik?.values?.conditions[index]?.nationality}
                formik={formik}
              />
            ))}
          </div>
          <p className="error check">
            <ErrorMessage name={`conditions.${index}.nationality`} />
          </p>
        </FieldWrapper>
      </div>
      <div className="inputs_group">
        <FieldWrapper title={t('salaryTransfer')}>
          <div className="checkboxes_wrapper row">
            <label>
              <input
                checked={allSalaryTransfer}
                id={`all`}
                type="checkbox"
                value={'all'}
                onChange={(e) => {
                  setAllSalaryTransfer(!allSalaryTransfer);
                  formik?.setFieldValue(`conditions.${index}.salary_transferred_to`, !allSalaryTransfer ? SalaryToCheck.map((item: any) => item.value) : []);
                }}
              />
              {t('all')}
            </label>
            {SalaryToCheck?.map((item: any, i: number) => (
              <Checkbox
                workSectors={SalaryToCheck as any}
                name={`conditions.${index}.salary_transferred_to`}
                key={i}
                item={item}
                value={
                  Array.isArray(formik?.values?.conditions[index]?.salary_transferred_to)
                    ? formik?.values?.conditions[index]?.salary_transferred_to?.some((checkedItem: string) => checkedItem == item?.value)
                    : false
                }
                checked={formik?.values?.conditions[index]?.salary_transferred_to}
                formik={formik}
              />
            ))}
          </div>
          <p className="error check">
            <ErrorMessage name={`conditions.${index}.salary_transferred_to`} />
          </p>
        </FieldWrapper>
      </div>
      <div className="inputs_group">
        <FieldWrapper title={t('workSectors')}>
          <div className="checkboxes_wrapper">
            <label>
              <input
                checked={all}
                id={`all`}
                type="checkbox"
                value={'all'}
                onChange={(e) => {
                  setAll(!all);
                  formik?.setFieldValue(`conditions.${index}.work_sectors`, !all ? workSectors.map((item: any) => item.value) : []);
                }}
              />
              {t('all')}
            </label>

            {workSectors?.map((item: any, i: number) => (
              <Checkbox
                workSectors={workSectors}
                name={`conditions.${index}.work_sectors`}
                key={i}
                item={item}
                value={formik?.values?.conditions[index]?.work_sectors?.some((checkedItem: string) => checkedItem == item?.value)}
                checked={formik?.values?.conditions[index]?.work_sectors}
                formik={formik}
              />
            ))}
          </div>
          <p className="error check">
            <ErrorMessage name={`conditions.${index}.work_sectors`} />
          </p>
        </FieldWrapper>
      </div>
    </>
  );
};

export default ConditionsInputs;
